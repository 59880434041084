import React from 'react';
import '../styles/styles.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="menu-footer">
        <div className="nav2-footer">
          <ul id='nav' className="navigation">
            <li><a href="/">Home</a></li>
            <li><a href="/Schedule" target="_blank" rel="noopener noreferrer">Schedule</a></li>
            <li><a href="/Programs">Programs</a></li>
            <li><a href="/About">About Us</a></li>
          </ul>
        </div>
      </div>
      <p>2024 &copy; Radio EL SAS is a trademark of MAF International. || Developed by <a href="https://www.marcoaflores.com" target="_blank" rel="noopener noreferrer">Marco A. Flores</a></p>  
    </footer>
  );
}

export default Footer;

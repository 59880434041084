import React, { useEffect } from 'react';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import ElSasGallery from '../media/images/elsas_library.jpg';
import '../styles/styles.css';

function Home() {

  return (
    <div className="contenedor-home">
      <NavBar />
      <div className="body-page">
        <div className="welcome-card">
          <p>Bienvenido a Radio ELSAS: La Excelencia Musical de la frontera, que desde Los Dos Laredos transmitimos las 24 horas, los 365 días del año con la mejor música del recuerdo y algo más. En nuestra programación encontrarás algunos espacios para acompañar tu vida espiritual, así como música instrumental para complementar tu noche. Somos una estación online de pocas palabras y de mucho ambiente, cualquier petición o sugerencia no dudes hacerla llegar a través de nuestras redes sociales. ¡Súbale!</p>
        </div>
        <div className="wonderful-player">
        <audio id="" src="https://stream.zeno.fm/gfd32dq5b48uv" className=" "controls></audio>
        </div>
        {/* <div className="playlist">
          <div class="cc_recenttracks_list" data-username="mrnavqfj">Loading ...</div>
        </div> */}
        <br />
        <br />
        <div className="img-gallery">
          <img src={ElSasGallery} alt="Nuestra Colección" className="image-gallery" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

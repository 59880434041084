import React from 'react';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2'
import Recents from '../components/Recents'

function SasAdmHm() {
  return (
    <>
        <h1>Welcome to Admin Page!</h1>
        <p></p>
        <Recents></Recents>
        


        <footer>

        </footer>

    </>
  );
}

export default SasAdmHm;
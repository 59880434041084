import React from 'react';
import ELSASlogo from '../media/images/elsas.png';
import '../styles/styles.css';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

function Programs() {
  return (
    <div className='contenedor-home'>
      <NavBar></NavBar>
      <div className="programs-header">
        <h2 className="title">Programas</h2>
        <p className="description">
          Radio EL SAS cuenta con dos programas originales que se transmiten un dia a la semana, consulta nuestra guía de programación y en caso de que no los hayas escuchado el día de su estreno, puedes escucharlo en su repetición o bien, en este espacio, donde los programas están siempre disponibles.
        </p>
      </div>

      <div className="seccion-programas">
        <div className="programa-title">
          <h3 className="p-programa-title">Desde La Tornamesa</h3>
        </div>
        <div className="programa-subtitle">
          <p className="p-programa-subtitle">
            Como en aquellos tiempos, cuando los formatos análogos eran los medios para escuchar la música tropical, en este programa recordamos a los grandes grupos de Nuevo Laredo y la región, reproduciendo sus éxitos desde el disco LP o el cassette en el que fueron grabados. No te pierdas este programa cada jueves a mediodía y los sábados la repetición a mediodía.
          </p>
        </div>
        <div className="programa-episodios">
          <table> 
            <thead>
              <tr>
                <th colspan="2">Desde La Tornamesa</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#</td>
                <td>Título</td>
                <td>Fecha de Estreno</td>
                <td>Escúchalo Aquí</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Programs;

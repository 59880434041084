import React from 'react';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2'


//La idea de este componente, es traer una tabla con la canción que acaba de reproducir, la que está en reproducción y la que se reproducirá a continuación.
function Recents() {
    return (
        <>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Hora</th>
                        <th>Artista</th>
                        <th>Título</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hace unos momentos</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>En estos momentos</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>En unos momentos</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>



            <footer>

            </footer>

        </>
    );
}

export default Recents;
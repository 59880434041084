import React from 'react';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/styles.css';
import Master from '../media/images/master_tape.jpg'
import ElSasGallery from '../media/images/elsas_library.jpg'

function About() {
  return (
    <div className='contenedor-home'>
      <NavBar></NavBar>
      <div className="programs-header">
        <h2 className="title">Sobre Nosotros</h2>
      </div>
      <div className="about-section">
        <div className="about-description">
          <p className="about-description-text">
            Radio EL SAS es una propuesta musical para todos los que gustan de la música del recuerdo, grupos de Nuevo Laredo y la región que dejaron huella en la historia musical y que merecen ser recordados. Contamos con una modesta biblioteca musical que contiene grabaciones que, aún con la aparación y auge de las plataformas digitales, no han podido preservarse, y con ayuda de colaboradores que se dedican a coleccionar música regional, hemos podido complementar nuestro catálogo musical y ser incluso una 'caja de sorpresas' para nuestros escuchas.
            <br />
            <br />

            Fundada en Julio de 2023 por el Ing. Marco A. Flores, nuestra misión es ofrecer contenido sano a nuestros escuchas, y es por eso que con mucho respeto, hemos colocado una serie de espacios de oración católica durante el día a día en nuestra programación, así como también, durante la noche, "cambiamos el ritmo" con música tranquila y de fácil escucha para relajarte y acompañar tus momentos. 
            <br />
            <br />

            En Radio EL SAS estamos muy contentos por el trabajo que hemos realizado en este tiempo y la búsqueda de la mejora continua en nuestro sitio, hemos llegado al punto de elaborar un sistema de transmisión propio que se adapte a nuestros objetivos. Este es un proyecto que hacemos de corazón y que esperamos que dure en el gusto de la gente mucho tiempo, recuerda: Radio EL SAS... La Excelencia Musical de la Frontera.
          </p>
        </div>
      <img src={Master} alt="" className="img-image" />
      </div>
      <div className="image-gallery-m-footer">
          <img src={ElSasGallery} alt="Nuestra Colección" className="image-gallery-footer" />
        </div>
      <Footer></Footer>
    </div>
  );
}

export default About;
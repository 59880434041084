import React from 'react';
// import ELSASlogo from '../media/images/elsas_alt.png';
import ELSASlogo from '../media/images/elsas_2024_release.png';

import '../styles/styles.css';

function Navbar() {
  return (
    <div className="menu">
      <div className="logo-container">
        <img src={ELSASlogo} className='imgProp' alt="Logo de ELSAS"/>
      </div>
      <div className="nav-container">
        <ul id='nav' className="navigation">
          <li className="navigation-li"><a href="/">Home</a></li>
          <li className="navigation-li"><a href="/Schedule">Schedule</a></li>
          <li className="navigation-li"><a href="/Programs">Programs</a></li>
          <li className="navigation-li"><a href="/About">About Us</a></li>
        </ul>
      </div>
    </div>
  );
}




export default Navbar;

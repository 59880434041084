import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/Navbar';
import pdfFile from '../../src/media/files/Schedule-January-2024.pdf';
import '../styles/styles.css';
import Footer from '../components/Footer';

function Schedule() {
  const navigate = useNavigate();

  // Function to handle the download link
  const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfFile;
    downloadLink.download = 'Schedule-January-2024.pdf';
    downloadLink.click();
  };

  return (
    <div className="contenedor-home">
      <NavBar></NavBar>
      <div className="programs-header">
        <h2 className="title">Programación</h2>
      </div>
      <div className="body-page">
        <div className="welcome-card">
          <p>Bienvenido a Radio ELSAS: La Excelencia Musical de la frontera, que desde Los Dos Laredos transmitimos las 24 horas, los 365 días del año con la mejor música del recuerdo y algo más. A continuación, te compartimos el calendario de nuestra programación. No te pierdas los programas especiales que con mucho cariño y respeto hemos realizado para ti. ¿Dudas? ¿Sugerencias? No dudes hacerlo llegar a través de nuestras redes oficiales.</p>
        </div>
        <object data={pdfFile} type="application/pdf" style={{ width: '100%', height: '600px' }}>
          <p>
            El visor de PDF no está disponible. Puedes descargar el archivo{' '}
            <button onClick={handleDownload}>aquí</button>.
          </p>
        </object>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Schedule;

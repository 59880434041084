import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SasAdmLgn from './components/SasAdmLgn';
import SasAdmHm from './components/SasAdmHm';
import Home from './components/Home';
import About from './components/About';
import Programs from './components/Programs';
import Schedule from './components/Schedule';
import './styles/styles.css'



// //PDF VIEWER
// function Schedule() {

//   return (
//     <div>
//       <NavBar></NavBar>
//       <h2>Schedule</h2>
//       <object data={require('../src/media/files/Schedule-January-2024.pdf')} type="application/pdf" width="100%" height="1200px">
//         <p>El visor de PDF no está disponible. Puedes descargar el archivo <a href={require('../src/media/files/Schedule-January-2024.pdf')}>aquí</a>.</p>
//       </object>
//     </div>
//   );
// }

function App() {
  return (
    <div className='body-bck'>
      <Router>
        <Routes>
          {/* Rutas comunes para los usuarios */}
          <Route path='/' element={<Home />} />

          {/* Rutas para administradores */}
          <Route path='/SasAdmLgn' element={<SasAdmLgn />} />
          <Route path='/SasAdmHm' element={<SasAdmHm />} />

          {/* Ruta para mostrar el PDF */}
          <Route path='/Schedule' element={<Schedule />} />
          
          {/* Ruta para la sección de Programas Grabados */}
          <Route path='/Programs' element={<Programs />} />

          {/* Ruta para la sección de About Us */}
          <Route path='/About' element={<About />} />

        </Routes>
      </Router>
      {/* https://cheatsheets.shecodes.io/ */}
    </div>
  );
}

export default App;
